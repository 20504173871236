export const generateElectramImage = (doors, width, chambers, height) => {
  switch (
    doors // Portas
  ) {
    case "1":
      switch (
        width // Largura
      ) {
        case "61cm":
          switch (
            chambers // Câmaras
          ) {
            case "3":
              if (height === "19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145663/Configurador-Electram/finais/61_ELECTRAM_3C_1P_hjwtmt.svg"
              } else if (height === "24cm-19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145663/Configurador-Electram/finais/61_ELECTRAM_3C_cn_ca_nf4yy0.svg"
              }
            case "4":
              if (height === "19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145663/Configurador-Electram/finais/61_ELECTRAM_4C_1P_irgsdk.svg"
              } else if (height === "24cm-19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145663/Configurador-Electram/finais/61_ELECTRAM_4C_1P_cn_ca_hlh5oi.svg"
              }
          }

        case "75cm":
          switch (chambers) {
            case "3":
              if (height === "19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145667/Configurador-Electram/finais/75_ELECTRAM_3C_1P_v3osr9.svg"
              } else if (height === "24cm-19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145667/Configurador-Electram/finais/75_ELECTRAM_3C_1P_cn_ca_xx2zba.svg"
              }
            case "4":
              if (height === "19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145667/Configurador-Electram/finais/75_ELECTRAM_4C_1P_bueomx.svg"
              } else if (height === "24cm-19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145667/Configurador-Electram/finais/75_ELECTRAM_4C_1P_cn_ca_a6gy5x.svg"
              }
          }

        case "82cm":
          switch (chambers) {
            case "3":
              if (height === "19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145672/Configurador-Electram/finais/82_ELECTRAM_3C_1P_tunyvo.svg"
              } else if (height === "24cm-19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145672/Configurador-Electram/finais/82_ELECTRAM_3C_1P_cn_ca_luh1gf.svg"
              }

            case "4":
              if (height === "19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145672/Configurador-Electram/finais/82_ELECTRAM_4C_1P_gcyk2z.svg"
              } else if (height === "24cm-19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145672/Configurador-Electram/finais/82_ELECTRAM_4C_1P_cn_ca_qhp6ef.svg"
              }
          }

        case "90cm":
          switch (chambers) {
            case "3":
              if (height === "19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145675/Configurador-Electram/finais/90_ELECTRAM_3C_1P_le55wq.svg"
              } else if (height === "24cm-19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145675/Configurador-Electram/finais/90_ELECTRAM_3C_1P_cn_ca_zjegvb.svg"
              }

            case "4":
              if (height === "19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145676/Configurador-Electram/finais/90_ELECTRAM_4C_1P_t2ttmh.svg"
              } else if (height === "24cm-19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145676/Configurador-Electram/finais/90_ELECTRAM_4C_1P_cn_ca_rfa7jn.svg"
              }

            case "5":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145676/Configurador-Electram/finais/90_ELECTRAM_5C_1P_dnpml6.svg"
          }
      }

    case "2":
      switch (width) {
        case "61cm":
          switch (chambers) {
            case "3":
              if (height === "19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145681/Configurador-Electram/finais/61_ELECTRAM_3C_2P_wac88p.svg"
              } else if (height === "24cm-19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145681/Configurador-Electram/finais/61_ELECTRAM_3C_2P_cn_ca_k7lk14.svg"
              }

            case "4":
              if (height === "19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145681/Configurador-Electram/finais/61_ELECTRAM_4C_2P_imjnop.svg"
              } else if (height === "24cm-19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145681/Configurador-Electram/finais/61_ELECTRAM_4C_2P_cn_ca_jqgvhr.svg"
              }

            case "5":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145681/Configurador-Electram/finais/61_ELECTRAM_5C_2P_cdzr5a.svg"
          }

        case "75cm":
          switch (chambers) {
            case "3":
              if (height === "19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145686/Configurador-Electram/finais/75_ELECTRAM_3C_2P_ycagsp.svg"
              } else if (height === "24cm-19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145686/Configurador-Electram/finais/75_ELECTRAM_3C_2P_cn_ca_mufdpb.svg"
              }

            case "4":
              if (height === "19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145686/Configurador-Electram/finais/75_ELECTRAM_4C_2P_w6c1qc.svg"
              } else if (height === "24cm-19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145686/Configurador-Electram/finais/75_ELECTRAM_4C_2P_cn_ca_msuqji.svg"
              }

            case "5":
              return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145686/Configurador-Electram/finais/75_ELECTRAM_5C_2P_xum0jf.svg"
          }
      }

    case "3":
      switch (width) {
        case "61cm":
          switch (chambers) {
            case "3":
              if (height === "19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145690/Configurador-Electram/finais/61_ELECTRAM_3C_3P_qgm7ud.svg"
              } else if (height === "24cm-19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145690/Configurador-Electram/finais/61_ELECTRAM_3C_3P_cn_ca_m6eoqp.svg"
              }

            case "4":
              if (height === "19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145690/Configurador-Electram/finais/61_ELECTRAM_4C_3P_erpf6d.svg"
              } else if (height === "24cm-19cm") {
                return "https://res.cloudinary.com/de6biukqo/image/upload/v1623145690/Configurador-Electram/finais/61_ELECTRAM_4C_3P_cn_ca_uv6o3y.svg"
              }
          }
      }
  }
}
